import axios from "axios";

const API_URL = process.env.GATSBY_API_URL;

if (API_URL === undefined) {
    console.error('API_URL is not set!')
}

const defaults = {
    params: {}
}

const defaultHeaders = {
    'Content-Type': 'application/json',
}

export function getEnums(options, callback, headers) {
    callback()
    const opt = Object.assign({}, defaults, options)
    const h = Object.assign({}, defaultHeaders, headers)

    return axios.get(`${API_URL}/v1/enums/list`, {
        headers: h,
        params: opt.params
    })
}

export function getReport(id, options, callback) {
    callback()
    const opt = {...defaults, options}

    return axios.get(`${API_URL}/v1/reports/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        params: opt.params
    })
}

export function getResults(id, options, callback) {
    callback()
    const opt = {...defaults, ...options}

    return axios.get(`${API_URL}/v1/reports/${id}/results`, {
        headers: {
            'Content-Type': 'application/json',
        },
        params: opt.params
    })
}

export function verifyUser(hash, options, callback) {
    callback()
    const opt = {...defaults, ...options}

    return axios.get(`${API_URL}/v1/respondents/verify/${hash}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        params: opt.params
    })
}

export function registerUser(payload, callback) {
    callback()

    return axios.post(`${API_URL}/v1/respondents/register`, payload,{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export function getQuestions(reportId, options, callback) {
    if (reportId === undefined) {
        return Promise.reject('something went wrong')
    }

    callback()
    const opt = {...defaults, options}

    return axios.get(`${API_URL}/v2/questionnaires/questions/by-report/${reportId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        params: opt.params
    })
}

export function saveAnswers(payload, callback) {
    callback()

    return axios.post(`${API_URL}/v2/questionnaires/questions/save-answers`, payload,{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export function sendProfile(payload, callback) {
    callback()

    return axios.patch(`${API_URL}/v1/respondents/profile`, payload,{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export function saveConsents(payload, callback) {
    callback()

    return axios.patch(`${API_URL}/v1/respondents/consents`, payload,{
        headers: {
            'Content-Type': 'application/json',
        }
    })
}
